import '@/styles/globals.css';
import '@/utils/amplitude.ts';
import 'utils/dayjs';

import { ApolloProvider } from '@apollo/client';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import Script from 'next/script';
import { NextComponentType } from 'next';
import React from 'react';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';

import client from '@/apollo-client';
import { AuthProvider } from '@/context/auth';
import { DoctorProvider } from '@/context/doctor';
import { ToastProvider } from '@/context/toast';
import { MedicalApplicationFormProvider } from '@/context/medicalApplicationForm';
import { MetaDoctorDocument, MetaHospitalDocument } from '@/graphql/generated/graphql';
import { HeadComponent } from '@/components/useCases';
import Error from '@/pages/_error/index.page';

require('dayjs/locale/ko');
dayjs.locale('ko');

const App: NextComponentType<AppContext, AppInitialProps, AppProps> = (
  props: AppProps
) => {
  const { Component, pageProps } = props;
  const router = useRouter();
  const initializeKakao = () => {
    window.Kakao.init(String(process.env.KAKAO_APP_KEY));
  };

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ToastProvider>
          <DoctorProvider>
            <MedicalApplicationFormProvider>
              <HeadComponent
                hospital={pageProps.data?.hospital}
                doctor={pageProps.data?.doctor}
              />
              <Script
                id={'GTM-PSNNBDC'}
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-PSNNBDC');`
                }}
              />
              <Script
                src="https://developers.kakao.com/sdk/js/kakao.js"
                onLoad={initializeKakao}
              />
              <Script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-Y2K62YHH9M"
              />
              <Script
                type={'text/javascript'}
                src="https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=ho8re5o048"
              />
              <Script
                id={'G-Y2K62YHH9M'}
                dangerouslySetInnerHTML={{
                  __html: `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
      
                    gtag('config', 'G-Y2K62YHH9M');`
                }}
              />
              {!router?.pathname?.includes('pharmacy') &&
              !router?.pathname?.includes('prescription') ? (
                <Error />
              ) : (
                <Component {...pageProps} {...props} />
              )}
            </MedicalApplicationFormProvider>
          </DoctorProvider>
        </ToastProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

App.getInitialProps = async (context: AppContext): Promise<AppInitialProps> => {
  const { ctx, Component } = context;
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  const { doctorId, hospitalId } = ctx.query;
  try {
    if (hospitalId) {
      const { data } = await client.query({
        query: MetaHospitalDocument,
        variables: { hospitalId }
      });
      if (data) return { pageProps: { ...pageProps, data, fallback: true } };
    }

    if (doctorId) {
      const { data } = await client.query({
        query: MetaDoctorDocument,
        variables: { doctorId }
      });
      if (data) return { pageProps: { ...pageProps, data, fallback: true } };
    }
    return { pageProps: { ...pageProps, fallback: true } };
  } catch (e) {
    return { pageProps: { ...pageProps } };
  }
};

export default App;
