import React from 'react';
import Lottie from 'react-lottie-player';
import { useRouter } from 'next/router';

import { Text } from '@/components/typographies';
import { COLORS } from '@/styles/colors';
import { Button } from '@/components/forms';
import errorAnimation from '@/assets/lotties/lottie_caution.json';
import { NavigationBar, StickyBottomView } from '@/components/useCases';
import Template from '@/components/useCases/Template';

import LeftArrow from '../../assets/icons/ic_arrow_left_regular.svg';
import * as styles from './styles';

const Error = () => {
  const router = useRouter();
  const APP_LINK = `https://goodoc.onelink.me/7srm?af_dp=goodoc://app/untact/doctor/profile/0/DoctorListScreen`;

  return (
    <Template.Page className={styles.errorContainer}>
      <NavigationBar
        leftComponent={
          <LeftArrow
            className={styles.backIcon}
            width={24}
            height={24}
            color={COLORS.GRAY_90}
            onClick={() => router.back()}
            alt="굿닥 비대면진료 뒤로가기"
          />
        }
      />
      <div className={styles.topContainer}>
        <div className={styles.titleContainer}>
          <Text type="heading3_700" color={COLORS.GRAY_100}>
            비대면 진료 웹 서비스 종료
          </Text>
          <Text type="body1_400" color={COLORS.GRAY_80} className={styles.title}>
            {`굿닥 비대면 진료 웹 서비스가 종료되었습니다.\n비대면 진료는 굿닥 앱을 통해서 이용하실 수 있습니다.\n불편을 드려 죄송하며, 더욱 편리한 서비스를 제공하기 위해 노력하겠습니다.`}
          </Text>
        </div>
        <Lottie loop play animationData={errorAnimation} className={styles.errorIcon} />
      </div>
      <StickyBottomView>
        <Button
          styleType="blueFilled"
          size="large"
          className={styles.footerButton}
          onClick={() => router.push(APP_LINK)}
        >
          굿닥 앱에서 비대면 진료 받기
        </Button>
      </StickyBottomView>
    </Template.Page>
  );
};

export default Error;
